import Vue from "vue";
import Router from "vue-router";
import { routes } from './routes.js'

//Components
Vue.use(Router);

let router = new Router({
    linkExactActiveClass: "active",
    mode: "history",
    routes,

    scrollBehavior () {
        return { x: 0, y: 0 }    
    }
});

export default router;