export const routes = [
    {
        path: "*",
        redirect: "/",
    },
    {
        path: "/",
        name: "landing",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./pages/Landing.vue"),
    },        

    //Articles
    {
        path: "/articles",
        name: "articles",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./pages/Articles.vue"),
    },
    {
        path: "/articles/why-wanna-be-ceo",
        name: "why-wanna-be-ceo",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/why-wanna-be-ceo/why-wanna-be-ceo.vue"),
    },
    {
        path: "/articles/about-the-author",
        name: "about-the-author",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/about-the-author/about-the-author.vue"),
    },
    {
        path: "/articles/why-do-i-want-to-become-an-entrepreneur",
        name: "why-do-i-want-to-become-an-entrepreneur",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/why-do-i-want-to-become-an-entrepreneur/why-do-i-want-to-become-an-entrepreneur.vue"),
    },
    {
        path: "/articles/start-a-business-while-working-full-time",
        name: "start-a-business-while-working-full-time",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/start-a-business-while-working-full-time/start-a-business-while-working-full-time.vue"),
    },
    {
        path: "/articles/should-i-share-my-business-ideas",
        name: "should-i-share-my-business-ideas",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/should-i-share-my-business-ideas/should-i-share-my-business-ideas.vue"),
    },
    {
        path: "/articles/2020-review",
        name: "2020-review",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/2020-review/2020-review.vue"),
    },
    {
        path: "/articles/mixing-family-and-business",
        name: "mixing-family-and-business",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/mixing-family-and-business/mixing-family-and-business.vue"),
    },
    {
        path: "/articles/writing-process",
        name: "writing-process",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/writing-process/writing-process.vue"),
    },
    {
        path: "/articles/likab",
        name: "likab",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/likab/likab.vue"),
    },

    
    //Journaling
    {
        path: "/articles/journal/introduction",
        name: "journal-introduction",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/journal/journal-introduction.vue"),
    },
    {
        path: "/articles/journal/facing-my-fears",
        name: "facing-my-fears",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/journal/facing-my-fears.vue"),
    },
    {
        path: "/articles/journal/what-am-i-working-on",
        name: "facing-my-fears",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/journal/what-am-i-working-on.vue"),
    },
    {
        path: "/articles/journal/why-listen-to-a-wannabe",
        name: "why-listen-to-a-wannabe",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/journal/why-listen-to-a-wannabe.vue"),
    },
    {
        path: "/articles/journal/why-dont-i-feel-as-happy-as-i-should",
        name: "why-dont-i-feel-as-happy-as-i-should",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/journal/why-dont-i-feel-as-happy-as-i-should.vue"),
    },
    {
        path: "/articles/journal/what-i-want-to-achieve-in-2021",
        name: "what-i-want-to-achieve-in-2021",
        component: () =>
            import( /* webpackChunkName: "Landing" */ "./articles/journal/what-i-want-to-achieve-in-2021.vue"),
    },
]