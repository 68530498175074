import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        'fr': 'FR',
        'en': 'EN',
        'frLong': 'French',
        'enLong': 'English',
        'languageChangeFor': 'Language changed for',

        //Navigation
        'navAbout': 'About the author',
        'navWhyTheName': 'Why the name ?',

        //Home page
        'hello': 'Hello',
        'whoAmI': 'Welcome to The Wanna Be CEO',
        'whatIdo': 'An aspiring entrepreneur\'s journey to success',
        'homeCTAArticle': 'Take me to the articles',
        

        //Let's talk
        'letsTalkTitle': 'Let\'s talk',
        'letsTalkDesc1': 'If you find any of my stuff interesting, you can learn more on my',
        'letsTalkDesc2': 'You can also reach out via these methods:  ',

    }
}

const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'fr', // set fallback locale
    messages, // set locale messages
});

export default i18n;