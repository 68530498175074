import Vue from 'vue'
import App from './App.vue'
import router from './router'

import i18n from '@/plugins/i18n';
import Toasted from 'vue-toasted';
import underscore from 'vue-underscore';
import VueMeta from 'vue-meta';
import Axios from 'axios'

import VueDisqus from 'vue-disqus'

import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/sass/_styles.scss';
import 'bootstrap'

import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

require('dotenv').config()

Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
Axios.defaults.withCredentials = false;
Axios.defaults.headers.common[process.env.VUE_APP_API_KEY] = process.env.VUE_APP_API_SECRET;

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Toasted)
Vue.use(underscore)
Vue.use(VueDisqus, {
    shortname: 'the-wanna-be-ceo'
  });

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
  });
  
Vue.config.productionTip = false

//Instance properties
Vue.prototype.$axios = Axios;

new Vue({
    router,
    i18n,
    render: h => h(App),
}).$mount('#app')